body {
  background-color: #000;
  overflow: hidden;
  /* margin-bottom: 100px; */
}

.App {
  display: grid;
  grid-template-rows: 60px 1fr 52px;
  height: 100vh;
}

.Header {
  border-bottom: 1px solid #CCC;
  background-color: #FFF;
  background-color: #2b2d2f;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header-bar {
  padding:12px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.Main {
  /* margin-top: 4rem; */
  /* margin-bottom: 4rem; */
  height: 100%;
  overflow: auto;
  background-color: #000;
}

.Footer {
  padding: 12px;
  border-top: 1px solid #CCC;
  background-color: #FFF;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #2b2d2f;
}

.footer-bar {
  display: flex;
  justify-content: space-between;
  color: white;
}

.page {
  margin-top: 60px;
  background-color: black;
  height: calc(100% - 60px);
}

.page-content {
  padding: 12px 8px 8px 8px;
  font-family: 'Roboto', sans-serif !important;
  color: #FFF
  
}

.map {
  height: 100%;
}

.map-filter {
  position: absolute; 
  left: 50%;
  bottom: 5rem; 
  z-index: 1;
  margin-left: -30px;
}

.map-filter-description {
    position: absolute;
    top: 4.4rem;
    left: 13.2rem;
    background-color: #FFF;
    z-index: 1;
    padding: 5px 8px;
    opacity: 1;
    border: 1px solid #FFF;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-weight: 600;
    height: 40px;
    width: 40px;
}

.Comments {
    background-color: #1e1f21;
    z-index: 2;
}

.comment-header {
  padding: 6px 0;
  border-top: 1px solid #CCC ;
  border-Bottom: 1px solid #CCC;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #FFF;
  text-align: center;
}

.comment-box {
  color: #FFF;
  padding: 12px;
  padding-bottom: 4rem;
  font-family: 'Anton', sans-serif !important;
  
}

.comment-message {
  margin: 8px 0;
}

.announcement-box {
  padding: 12px;
  border-bottom: 1px dotted #000;
  font-family: 'Anton', sans-serif !important;
}

.Drawer {
  position: absolute;
  width: 100%;
  height: 0%;
  min-height: 0%;
  max-height: 100%;
  transition: min-height 0.25s ease-in;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 2;
}

.drawer-header {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CCC;
  background-color: #2b2d2f;
  
  
}

.drawer-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  margin-right: 40px;
  color: #FFF;
}

.emergency-info {
  color: blue;
}

.sponsors {
  position: fixed;
  bottom: 52px;
  padding: 0.5rem 0;
  background-color: dodgerblue;
  width: 100%;
  transform:translateY(60px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 2;
}

.sponsors-body {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.sponsors-text {
  color: #FFF;
  margin-top: 5px;
  font-family: 'Anton', sans-serif !important;
}

.sponsors-link {
  color: #FFF;
}

.list-default {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-menu {
  list-style-type: none;
  padding: 0;
}

.list-menu li {
  padding: 0.5rem 1rem;
}

.list-menu a {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  
}

.list-menu-date {
  margin-top: 1rem;
  margin-left: 1rem;
  font-size: 15px;
  color: #ffffff;
}

.Media-page {
  background-color: #FFF;
}

.MuiDrawer-paper{
  background-color:#2b2d2f !important;
}

#contact{
  text-align: center !important;
}

.MuiDivider-root{
  background-color: #2b2d2f !important;
}

.MuiSvgIcon-root{
height: none !important ;

}

#logo{
  width: 2rem;
}

.rack__drawer {
  position: fixed;
  bottom: 0px;
  height: 10;
  width: 100%;
  min-height: 0%;
  transition: min-height 0.25s ease-in;
  display: none;
  overflow: scroll;
  height:0%;
  max-height: 75%;
}

.rack__drawer-header {
  padding: 6px 0;
  border-top: 1px solid #CCC ;
  border-Bottom: 1px solid #CCC;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #FFF;
  text-align: center;
}

/*****************
*    Carousel    *
******************/
.carousel {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.carousel-slide {
  display: none;
}

.carousel-prev, .carousel-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.carousel-next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.carousel-prev:hover, .carousel-next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.carousel-text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

/*****************
*    SpeedDial    *
******************/

.speed-dial {
  /* position: fixed;
  bottom: 15px;
  right: 15px; */
}

.speed-dial__options {
  position: absolute;
  bottom: 100%;
  width: 100%;
  text-align: center;
}

.speed-dial__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-bottom: 15px;
  background: #000;
  color: #FFF;
  border-radius: 50%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  transition: background 0.2s, opacity 0.2s, transform 0.2s;
}

.speed-dial__button:active {
  background: #aaaaaa;
}

.speed-dial__button--primary {
  margin-bottom: 0;
  padding: 18px;
  background: #FFF;
  color: rgb(86, 86, 86);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.speed-dial__button--primary:active {
  background: #cccccc;
}

.speed-dial__button:not(.speed-dial__button--primary) {
  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
}

.speed-dial--active .speed-dial__button {
  opacity: 0.8;
  transform: translateY(0);
  visibility: visible;
}